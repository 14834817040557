      <h2 class="text-3xl md:text-4xl font-semibold sticky top-0 bg-white mt-6">Skills</h2>

      <h3 class="text-xl md:text-2xl font-semibold mt-3 mb-2 sticky top-0 bg-white">20+ Years of Experience</h3>

      <div class="flex items-center gap-3 mt-3 pb-3 sticky top-[34px] bg-white">
        <h3 class="text-[#865e16] font-semibold -ml-3 pl-3">Design</h3>
        <div class="flex-grow w-full h-[1px] bg-[#dfc398]" />
      </div>
      <ul class="list-disc ml-4">
        <li>UI/UX and graphic design mastery</li>
        <li>Adobe Suite, Figma, Sketch, Adobe XD</li>
        <li>High-fidelity prototypes and wireframes</li>
        <li>Motion design & micro-interactions</li>
        <li>Rapid prototyping and iteration</li>
        <li>Responsive-first design</li>
        <li>Scalable design systems architect</li>
      </ul>

      <div class="flex items-center gap-3 mt-4 pb-3 sticky top-[34px] bg-white">
        <h3 class="text-[#865e16] font-semibold -ml-3 pl-3">Develop</h3>
        <div class="flex-grow w-full h-[1px] bg-[#dfc398]" />
      </div>
      <ul class="list-disc ml-4">
        <li>Web tech: JS, TS, HTML5, CSS3</li>
        <li>Frameworks: React, Vue, Angular, Next.js</li>
        <li>CSS: Tailwind, Bootstrap</li>
        <li>Backends: Node.js, Python, PHP</li>
        <li>Deployment: AWS, Azure, Vercel</li>
        <li>Databases: PostgreSQL, MongoDB</li>
        <li>Git power user</li>
        <li>API design: REST, GraphQL</li>
        <li>Performance tuning</li>
      </ul>

      <div class="flex items-center gap-3 mt-4 mb-3 sticky top-[34px] bg-white">
        <h3 class="text-[#865e16] font-semibold -ml-3 pl-3">Systems</h3>
        <div class="flex-grow w-full h-[1px] bg-[#dfc398]" />
      </div>
      <ul class="list-disc ml-4">
        <li>Linux, BSD system admin</li>
        <li>Containerization: Docker, Kubernetes</li>
        <li>Infrastructure: Terraform, Ansible</li>
        <li>Monitoring: Prometheus, Grafana, Kibana</li>
        <li>Shell scripting: Bash, Zsh</li>
        <li>Network security: Wireshark, Nmap</li>
        <li>Cloud: AWS, GCP, Azure, Vercel</li>
        <li>CI/CD: GitLab, GitHub Actions, CircleCI</li>
        <li>Hardware diagnostics, forensic analysis</li>
      </ul>
